<template>
  <div class="card">
    <div class="chart" :key="reloadChart">
      <apexchart
        :options="options"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { parseNumberToMoney } from '@/utils/moneyHelper'
export default {
  components: {
    'apexchart': VueApexCharts,
  },
  props: {
    getBillsBalance: { type: Function, required: true },
    billsBalance: Object,
  },
  watch: {
    billsBalance(data) {
      if(data && data.labels.length) {
        this.setLabelsAndSeries({ ...data });
      }
    }
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      series: [],
      options: {
        labels: [],
        chart: {
          height: 'auto',
          width: '100%',
          stacked: true
        },
        colors: ["#FBA59D", "#00C773", "#0C1D59"],
        stroke: {
          width: 2
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1],
          formatter: function (val) {
              return parseNumberToMoney(val)
          },
          textAnchor: 'middle',
          distributed: true,
          offsetX: 0,
          offsetY: -15,
          style: {
              fontSize: '12px',
              fontFamily: 'Nunito Sans',
              fontWeight: 600,
              colors: ['#E7ECFE']
          },
          background: {
            enabled: true,
            foreColor: '#525C7A',
            padding: 4,
            borderRadius: 2
          },
        },
        markers: {
          size: 4,
          colors: undefined,
          strokeColors: '#0C1D59',
          strokeWidth: 0,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 0
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#8696AC',
            },
            formatter: function (y) {
              return Math.trunc(y)
            }
          }
        },
        xaxis: {
          type: 'category'
        }
      },
      reloadChart: 0
    }
  },
  methods: {
    setLabelsAndSeries({ labels, entrances, outputs, differences }) {
      this.options = { ...this.options, labels };
      this.series = [
        {
          name: 'Saídas',
          type: 'bar',
          data: outputs
        },
        {
          name: 'Entradas',
          type: 'bar',
          data: entrances
        },
        {
          name: 'Saldo total',
          type: 'line',
          data: differences
      }];

      this.reloadChart++
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    display: flex;
    height: auto;
    width: 100%;
    border-radius: 8px;
    text-align: left;
    justify-content: center;
    border: none;

    .chart {
      .header {
        display: flex;
        flex-direction: row;
        padding: 20px 20px 0 20px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        color: var(--dark-blue);
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

</style>
